import React from "react";
import "./experience.css";
// import { BsPatchCheckFill } from "react-icons/bs";

const languages = [
  "HTML",
  "CSS",
  "Javascript",
  "Typescript",
  "Python",
  "C#",
  "Java",
  "Groovy",
  "SQL",
  "Solidity",
  "VB",
  "YAML",
];

const frameworks = [
  "React",
  "Next.js",
  "Angular",
  "Django & DRF",
  "ASP.NET Core",
  "Node.js",
  "Cloudflare worker",
  "Cloudflare R2",
  "Redis",
  "DynamoDB",
  "SQS",
];

const Experience = () => {
  return (
    <section id="experience">
      <h5>My Expertise</h5>
      <h2>Key Skills</h2>

      <div className="container experience__container">
        <div className="experience__programming">
          <h3>Programming Languages</h3>
          <div className="experience__content">
            <ul className="skills__list">
              {languages.map((language) => (
                <li key={language} className="skills__list-item btn btn--plain">
                  {language}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="experience__framework">
          <h3>Framework</h3>
          <div className="experience__content">
            <ul className="skills__list">
              {frameworks.map((framework) => (
                <li
                  key={framework}
                  className="skills__list-item btn btn--plain"
                >
                  {framework}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
