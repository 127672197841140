import React from "react";
import "./portfolio.css";

import IMG1 from "../../assets/grpc-logo.png";
import IMG2 from "../../assets/trade.jpg";
import IMG3 from "../../assets/nft.jpg";
import IMG4 from "../../assets/discord_chatbot.jpg";
import IMG5 from "../../assets/budgeting_app.jpg";
import IMG6 from "../../assets/andriod.jpg";
import FORTUNE from "../../assets/fortunewishes.jpg";
import DELIVERY from "../../assets/delivery.jpg";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import VideoIframe from "./VIdeoIframe.tsx";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const data = [
  {
    image: FORTUNE,
    video: null,
    title: "AI Generated Greeting Card",
    functions:
      "Created and launched a user-friendly website that allows users to generate and purchase stunning AI-created greeting card covers from custom prompts. The site leveraged advanced FLUX.1 AI for high-quality image generation and integrated LLM models to enhance the user experience. Implemented Next.js edge runtime with Cloudflare Workers to ensure low latency and improved performance by processing data closer to the source.",
    github: false,
    link: "https://fortunewishes.com/",
    iframe: false,
    label: "Website",
  },
  {
    video:
      "https://drive.google.com/file/d/10EziicYh6oaSimW-K3Sv9TYID8PfWwI8/preview",
    title: "Fuzzy Logic Agent For Racing (Course: Game AI)",
    functions:
      "Implemented a Fuzzy Logic Agent that can race on a procedurally generated track and achieved a 46 Km/h average speed on the track over 5 minutes with no wipeout. (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: true,
  },
  {
    image: IMG2,
    video: null,
    title: "Stock Trading Journal Analysis",
    functions:
      "Built an interactive system for analyzing profit and loss of over 50,000 stock transactions, delivering critical performance insights to traders. Developed RESTful API backend with ASP.NET Core and responsive frontend with React and Chart.js for data visualization, providing customizations of views and plots.",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: DELIVERY,
    video: null,
    title: "Grocery Delivery Tracker (Course: Software Architecture & Design)",
    functions:
      "Developed a web application using Java and Spring Boot to manage manage customer orders and drivers, track delivery statuses and statistics. Implemented RESTful API backend with Spring Boot and responsive frontend with React. (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG1,
    video: null,
    title:
      "GRPC and Distributed File Systems (Course: Graduate Introduction to Operating Systems)",
    functions:
      "Applied gRPC and Protocol Buffers to design and implement a file transfer protocols that followed a weakly consistent synchronization design to manage cache consistency between multiple clients and a server. Developed functions including fetching a file from a server, storing a file to a server, preventive writer locks that only allow one writer per file at a time, auto synchronization between clients and server, multi-threaded server. (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG6,
    video: null,
    title:
      "Job Offer Comparison Andriod App (Course: Software Development Process)",
    functions:
      "Built an Andriod application to help people compare job offers with benefits, in different locations, and other aspects beyond salary.  (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG3,
    video: null,
    title: "Demo Ethereum Non-Fungible Token (NFT)",
    functions:
      "Developed and implemented ERC721 standard smart contract to create NFT collections using Solidity. The smart contract was optimized through merkle tree data structure for whitelisted buyers. It is deployed in the Ethereum Mainnet and listed in https://opensea.io/",
    github: true,
    link: "https://github.com/lok-w/ERC721A-NFT-contract",
    iframe: false,
    label: "Github",
  },
  {
    image: IMG4,
    video: null,
    title: "Discord Chatbot",
    functions:
      "Built a Discord chatbot using Python for members to submit and validate their eligibility of whitelist sale. The chatbot applied Google Sheet API as a database to store and retrieve member data.",
    github: true,
    link: "https://github.com/lok-w/discord-bot-eligibility-checker",
    iframe: false,
    label: "Github",
  },
  {
    image: IMG5,
    video: null,
    title: "Budgeting App",
    functions:
      "Built a desktop application for managing personal accounts and tracking expenses using Java. Applied JavaFX and CSS for GUI to visualize the data entries and intuitive user input form.",
    github: true,
    link: "https://github.com/lok-w/budgeting-app",
    iframe: false,
    label: "Github",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Projects</h5>
      <h2>Portfolio</h2>

      <Swiper
        className="container portfolio__container"
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map(
          (
            { image, video, title, functions, github, link, iframe, label },
            index
          ) => {
            if (link !== "#" && label) {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-image">
                    <img src={image} alt="" />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>

                  <div className="portfolio__item-cta">
                    <a
                      href={link}
                      className="portfolio__item-cta btn btn-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  </div>
                </SwiperSlide>
              );
            } else if (iframe) {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-video">
                    <VideoIframe videoURL={video} />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-image">
                    <img src={image} alt="" />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>
                </SwiperSlide>
              );
            }
          }
        )}
      </Swiper>
    </section>
  );
};

export default Portfolio;
