import React from "react";
import "./about.css";
import ME from "../../assets/me.jpg";
import { GoLocation } from "react-icons/go";
import { TbLanguageKatakana } from "react-icons/tb";
import { BsFillPencilFill } from "react-icons/bs";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GoLocation className="about__icon" />
              <h5>Location</h5>
              <small>Toronto, Canada</small>
            </article>

            <article className="about__card">
              <BsFillPencilFill className="about__icon" />
              <h5>Education</h5>
              <small>MS in Computer Science</small>
            </article>

            <article className="about__card">
              <TbLanguageKatakana className="about__icon" />
              <h5>Languages</h5>
              <small>English, Mandarin, Cantonese</small>
            </article>
          </div>

          <p>
            Driven by a passion for creating impactful software solutions.
            Thriving on challenges, and always eager to contribute innovative
            ideas and technical insights to dynamic teams. Whether it's tackling
            complex algorithms or designing user-focused interfaces, both
            creativity and precision are brought to every project.
          </p>

          <p>
            A proactive go-getter with strong analytical skills enhanced by rich
            cross-cultural experiences. Possessing a background that includes
            working within diverse settings and collaborating with people from
            various cultures, which has sharpened problem-solving abilities and
            broadened perspectives on global trends.
          </p>

          <small>
            <p>
              Fun Fact: Java Road is named after the Dutch shipping firm
              Koninklijke Java-China Paketvaart Lijnen which had its operational
              headquarters in Hong Kong in 1900s.
            </p>
          </small>

          <a href="mailto:lwongnm@gmail.com" className="btn btn-primary">
            Contact Me
          </a>
        </div>

        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
